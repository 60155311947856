import MenuItem from '@mui/material/MenuItem';

export let baseUrl = "";

if (window.location.href.includes("http://localhost:3000/")) {
	baseUrl = "http://localhost:3001";
	//baseUrl = "https://portal-da-liturgia-back.vercel.app";
} else {
	baseUrl = "https://portal-da-liturgia-back.vercel.app";
}

const partesMissaCadastro = [
	<MenuItem style={{fontFamily: "arial"}} value={1}>Entrada</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={2}>Ato Penitencial</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={3}>Glória</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={4}>Salmo Responsorial</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={5}>Ofertório</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={6}>Santo</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={7}>Doxologia</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={8}>Cordeiro de Deus</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={9}>Comunhão</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={10}>Ladainhas</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={11}>Canto Final</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={12}>Natal</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={13}>Nossa Senhora</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={14}>Aclamação do Evangelho</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={15}>Batismo</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={16}>Casamento</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={17}>Crisma</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={18}>Ordenação Presbiteral</MenuItem>,
    <MenuItem style={{fontFamily: "arial"}} value={19}>Retiro de Casais</MenuItem>
];

export const getTemaMissa = (parteMissa) => {
	if (parteMissa == 'entrada') {
        return 1;
    } else if (parteMissa == 'ato-penitencial') {
        return 2;
    } else if (parteMissa == 'gloria') {
        return 3;
    } else if (parteMissa == 'salmo') {
        return 4;
    } else if (parteMissa == 'ofertorio') {
        return 5;
    } else if (parteMissa == 'santo') {
        return 6;
    } else if (parteMissa == 'amem') {
        return 7;
    } else if (parteMissa == 'cordeiro-de-deus') {
        return 8;
    } else if (parteMissa == 'comunhao') {
        return 9;
    } else if (parteMissa == 'ladainhas') {
        return 10;
    } else if (parteMissa == 'canto-final') {
        return 11;
    } else if (parteMissa == 'natal') {
        return 12;
    } else if (parteMissa == 'nossa-senhora') {
        return 13;
    } else if (parteMissa == 'aclamacao') {
        return 14;
    } else if (parteMissa == 'batismo') {
        return 15;
    } else if (parteMissa == 'casamento') {
        return 16;
    } else if (parteMissa == 'crisma') {
        return 17;
    } else if (parteMissa == 'orderdenacao-presbiteral') {
        return 18;
    } else if (parteMissa == 'retiro-de-casais') {
        return 19;
    } else {
        return -1;
    }
}

export const getTemaMissaCadastro = (parteMissa) => {
    console.log("recebeu");
    console.log(parteMissa);
    if (parteMissa == 'Entrada' ) {
        return 1;
    } else if (parteMissa == 'Ato Penitencial' ) {
        return 2;
    } else if (parteMissa == 'Glória' ) {
        return 3;
    } else if (parteMissa == 'Salmo Responsorial' ) {
        return 4;
    } else if (parteMissa == 'Ofertório') {
        return 5;
    } else if (parteMissa == 6 ) {
        return 'Santo';
    } else if (parteMissa == 'Doxologia' ) {
        return 7;
    } else if (parteMissa == 'Cordeiro de Deus' ) {
        return 8;
    } else if (parteMissa == 'Comunhão') {
        return 9;
    } else if (parteMissa == 'Ladainhas') {
        return 10;
    } else if (parteMissa == 'Canto Final') {
        return 11;
    } else if (parteMissa == 'Natal') {
        return 12;
    } else if (parteMissa == 'Nossa Senhora') {
        return 13;
    } else if (parteMissa == 'Aclamação do Evangelho') {
        return 14;
    } else if (parteMissa == 'Batismo') {
        return 15;
    } else if (parteMissa == 'Casamento') {
        return 16;
    } else if (parteMissa == 'Crisma') {
        return 17;
    } else if (parteMissa == 'Ordenação Presbiteral') {
        return 18;
    } else if (parteMissa == 'Retiro de Casais') {
        return 19;
    }
}


