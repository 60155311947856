import {useState, useEffect} from 'react';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

// baseUrl
import {baseUrl} from "../../../services/adalConfig.js";

{
  
}

export default function Subsidios(props) {

	const [valuesDict, setValuesDict] = useState({"nome_documento": "", "caminho_arquivo": "", "tipo_documento": "", "tipo_subsidio": 1});
	const [rows, setRows] = useState([]);
	const [tiposSubsidios, setTiposSubsidios] = useState([]);
	const [check, setCheck] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const changeValues = (field, newValue) => {
		console.log("oi");
		var tempDict = valuesDict;

		tempDict = {...tempDict, [field]: newValue};
		console.log(tempDict);
		setValuesDict(tempDict);
	}

	async function handleEditRow(rowDict) {

		if (rowDict.texto_oracao_lt == "" || rowDict.texto_oracao_lt == null) {
			setCheck(false)
		} else {
			setCheck(true);
		}

		setValuesDict({...rowDict});
		props.setIsUpdateMode(true);
	}

	function cancelEditRow() {
		setValuesDict({"nome_documento": "", "caminho_arquivo": "", "tipo_documento": "", "tipo_subsidio": 1});
		props.setIsUpdateMode(false);
		setCheck(false);
		atualizaDados();
	}

	async function submitForm() {

		if (props.isUpdateMode) {
			var postUrl = baseUrl + "/update-subsidio";
		} else {
			var postUrl = baseUrl + "/cadastrar-subsidio";
		}
		
		var payload = valuesDict;

		var emptyValues = [];
		var payloadKeys = Object.keys(payload);
		console.log("keys");
		console.log(payloadKeys);
		for (let i = 0; i < payloadKeys.length; i++) {
			if (payload[payloadKeys[i]] == "" || payload[payloadKeys[i]] == null) {
				emptyValues.push(payloadKeys[i]);
			}
		}

		if (emptyValues.length > 0) {
			props.setAlertDict({open: true, texto: `Os seguintes campos estão vazios: ${emptyValues.join(", ")}`, severidade: "error"});
			return;
		}

		payload["token"] = props.token;

		console.log("Teste");
		console.log(payload);

		setLoading(true);

		axios.post(postUrl, {
				...payload
			})
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				setLoading(false);
				props.setAlertDict({open: true, texto: "Sucesso ao inserir novo subsídio!", severidade: "success"});
				cancelEditRow();
            })
            .catch((exp) => {
            	setLoading(false);
				props.setAlertDict({open: true, texto: "Erro ao inserir novo subsídio.", severidade: "error"});
            });
	}

	const columns = [
		{
    		field: 'nome_documento',
		    headerName: 'Nome Documento',
		    width: 140,
		    flex: 1
    	},
    	{
    		field: 'caminho_arquivo',
		    headerName: 'Link arquivo',
		    width: 140,
		    flex: 1,
		    renderCell: (params) => <a href={params.row.caminho_arquivo} target="_blank">{params.row.caminho_arquivo}</a>
    	},
    	{
    		field: 'tipo_subsidio',
		    headerName: 'Tipo subsídio',
		    width: 140,
		    flex: 1,
		    valueGetter: (value) => getTipoSubsidio(value)
    	},
    	{
    		field: 'tipo_documento',
		    headerName: 'Categoria subsídio',
		    width: 140,
		    flex: 1
    	},
    	{
	        field: 'actions',
	        type: 'actions',
	        width: 80,
	        getActions: (params) => {

		    		return ([
		        	          	<EditIcon
		        	          		onClick={() => handleEditRow(params.row)}
		        	          	/>,
		        	          	<DeleteIcon
					          		onClick={() => props.handleDeleteDialog("tb_subsidios", params.row.nome_documento, params.row.id, atualizaDados)}
					          	/>
		        	        ]);
		    	},
	    }
    ];

    function atualizaDados() {
    	var getUrl = baseUrl + "/get-subsidios";

        axios.get(getUrl)
            .then(resp => { 
                setRows(resp.data); 
            });
    }

    function getSubsidiosPossiveis() {
    	var getUrl = baseUrl + "/get-tipos-subsidios";

        axios.get(getUrl)
            .then(resp => {
                setTiposSubsidios(resp.data); 
                console.log("to strawberry fields");
                console.log(resp.data);
            });
    }

    function getTipoSubsidio(id_tipo) {
    	if (id_tipo == 1) return "Documento"
    	else if (id_tipo == 2) return "Roteiros"
    	else return "Rituais"
    }

    useEffect(() => {

    	console.log("New value");
    	console.log(props.isEditMode);

    	if (props.isEditMode == true || rows.length == 0) {
    		atualizaDados();
    	}
    }, [props.isEditMode]);

    useEffect(() => {
    	getSubsidiosPossiveis();
    }, []);

    useEffect(() => {
    	if (props.isUpdateMode == false) {
    		cancelEditRow();
    	}
    }, [props.isUpdateMode]);

    console.log("mano do ceu");
    console.log(valuesDict);

    if (props.isEditMode == true && props.isUpdateMode == false) {
    	return (
    		<DataGrid
    			autoHeight 
		        rows={rows}
		        columns={columns}
		        pageSizeOptions={[50]}
		        style={{width: "85%"}}
		    />
    	);
    } else
		return (
		<form noValidate onSubmit={() => console.log("oi turma")} id="formulario-login-cadastro-dados">
			<TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Nome do documento *"
	          	value={valuesDict["nome_documento"]}
				onChange={(event) => {
				    changeValues("nome_documento", event.target.value);
				}}
	        />
	        <TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Link do arquivo *"
	          	value={valuesDict["caminho_arquivo"]}
				onChange={(event) => {
				    changeValues("caminho_arquivo", event.target.value);
				}}
	        />
	        <FormControl>
		      <FormLabel id="demo-radio-buttons-group-label">Tipo</FormLabel>
		      <RadioGroup
		        aria-labelledby="demo-radio-buttons-group-label"
		        name="radio-buttons-group"
		        value={valuesDict["tipo_subsidio"]}
				onChange={(event) => {
				    changeValues("tipo_subsidio", event.target.value);
				}}
		      >
		        <FormControlLabel value={1} control={<Radio />} label="Documento" />
		        <FormControlLabel value={2} control={<Radio />} label="Roteiro" />
		        <FormControlLabel value={3} control={<Radio />} label="Rituais" />
		      </RadioGroup>
		    </FormControl>
	       	<Autocomplete
				id="fixed-tags-demo"
				value={valuesDict["tipo_documento"]}
				onChange={(event, newValue) => {
					changeValues("tipo_documento", newValue)
				}}
				options={tiposSubsidios[valuesDict["tipo_subsidio"]]}
				style={{ width: "100%" }}
				renderInput={(params) => (
					<TextField {...params} 
						label="Categoria do subsídio *" />
				)}
				onInputChange={(event, newInputValue) => {
		          changeValues("tipo_documento", newInputValue)
		        }}
			/>
			
		    
		    <LoadingButton loading={loading} onClick={() => submitForm()} style={{backgroundColor: "#bc1200"}} variant="contained">
		    	Salvar dados
		    </LoadingButton>
		</form>
	);
}