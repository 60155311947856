import {useState, useEffect} from 'react';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputLabel from '@mui/material/InputLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

// baseUrl
import {baseUrl} from "../../../services/adalConfig.js";

const dictTemposLiturgicos = {
	1: 'todos-os-tempos',
	2: 'advento',
	3: 'quaresma',
	4: 'pascoa',
	5: 'tempo-comum'
}

export default function Missas(props) {

	const [valuesDict, setValuesDict] = useState({nome: "", tipo: "ehSolenidade"});
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const changeValues = (field, newValue) => {
		console.log("oi");
		var tempDict = valuesDict;

		tempDict = {...tempDict, [field]: newValue};
		console.log(tempDict);
		setValuesDict(tempDict);
	}

	async function handleEditRow(rowDict) {

		var tempTipo;
		if (rowDict.tipo == "Solenidade") {
			tempTipo = "ehSolenidade";
		} else if (rowDict.tipo == "Ocasião Especial") {
			tempTipo = "ehOcasiaoEspecial";
		}

		setValuesDict({...rowDict, tipo: tempTipo});
		props.setIsUpdateMode(true);
	}

	function cancelEditRow() {
		setValuesDict({nome: "", tipo: "ehSolenidade"});
		props.setIsUpdateMode(false);
		atualizaDados();
	}

	async function submitForm() {

		if (props.isUpdateMode) {
			var postUrl = baseUrl + "/update-missa";
		} else {
			var postUrl = baseUrl + "/cadastrar-missa";
		}
		
		var payload = valuesDict;

		var emptyValues = [];
		var payloadKeys = Object.keys(payload);
		console.log("keys");
		console.log(payloadKeys);
		for (let i = 0; i < payloadKeys.length; i++) {
			if (payload[payloadKeys[i]] == "" || payload[payloadKeys[i]] == null) {
				if (payloadKeys[i] == "tipo") 
					continue;
				else 
					emptyValues.push(payloadKeys[i]);
			}
		}

		if (emptyValues.length > 0) {
			props.setAlertDict({open: true, texto: `Os seguintes campos estão vazios: ${emptyValues.join(", ")}`, severidade: "error"});
			return;
		}

		payload["url"] = payload["nome"].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll("/", "").replaceAll("  ", " ").replaceAll("\n", "").replaceAll("\r", "-").replaceAll(" , ", "-").replaceAll(" ,", "-").replaceAll(", ", "-").replaceAll(" ", "-").replaceAll(",", "-").replaceAll(";", "-");

		payload["token"] = props.token;

		setLoading(true);

		axios.post(postUrl, {
			...payload,
			ehSolenidade: payload["tipo"] == "ehSolenidade"? 1 : 0,
			ehOcasiaoEspecial: payload["tipo"] == "ehOcasiaoEspecial"? 1 : 0
		})
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				setLoading(false);
				props.setAlertDict({open: true, texto: "Sucesso ao inserir nova missa!", severidade: "success"});
				cancelEditRow();
            })
            .catch((exp) => {
            	setLoading(false);
				props.setAlertDict({open: true, texto: "Erro ao inserir nova missa.", severidade: "error"});
            });
	}

	const columns = [
    	{
			field: 'nome',
		    headerName: 'Nome',
		    width: 140,
		    flex: 1
    	},
    	{
    		field: 'url',
		    headerName: 'URL',
		    width: 140,
		    flex: 1
    	}, 
    	{
    		field: 'tipo',
		    headerName: 'Tipo',
		    width: 140,
		    flex: 1
    	}, 
		{
	        field: 'actions',
	        type: 'actions',
	        width: 80,
	        getActions: (params) => {
	        	console.log(params.row.tipo);
	        	if (params.row.tipo == "Comum" || params.row.url == "festa-de-cristo-rei") {
		    		return [];
		    	} else {
		    		return ([
		        	          	<EditIcon
		        	          		onClick={() => handleEditRow(params.row)}
		        	          	/>,
		        	          	<DeleteIcon
					          		onClick={() => props.handleDeleteDialog("tb_missas", params.row.nome, params.row.id, atualizaDados)}
					          	/>
		        	        ]);
		    	}},
	    }
    ];

    function atualizaDados() {
    	var getUrl = baseUrl + "/get-missas-datagrid";

        axios.get(getUrl)
            .then(resp => { 
                setRows(resp.data); 
            });
    }

    useEffect(() => {

    	console.log("New value");
    	console.log(props.isEditMode);

    	if (props.isEditMode == true || rows.length == 0) {
    		atualizaDados();
    	}

    }, [props.isEditMode]);

    useEffect(() => {
    	if (props.isUpdateMode == false) {
    		cancelEditRow();
    	}
    }, [props.isUpdateMode]);

    if (props.isEditMode == true && props.isUpdateMode == false) {
    	return (
    		<DataGrid
    			autoHeight 
		        rows={rows}
		        columns={columns}
		        pageSizeOptions={[50]}
		        style={{width: "85%"}}
		    />
    	);
    } else
		return (
		<form noValidate onSubmit={() => console.log("oi turma")} id="formulario-login-cadastro-dados">
			<TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Título da missa*"
	          	value={valuesDict["nome"]}
				onChange={(event) => {
				    changeValues("nome", event.target.value);
				}}
	        />
	       	<FormControl>
		      <FormLabel id="demo-radio-buttons-group-label">Tipo</FormLabel>
		      <RadioGroup
		        aria-labelledby="demo-radio-buttons-group-label"
		        name="radio-buttons-group"
		        value={valuesDict["tipo"]}
				onChange={(event) => {
				    changeValues("tipo", event.target.value);
				}}
		      >
		        <FormControlLabel value="ehSolenidade" control={<Radio />} label="Solenidade" />
		        <FormControlLabel value="ehOcasiaoEspecial" control={<Radio />} label="Ocasião Especial (Batismos, Casamentos etc)" />
		      </RadioGroup>
		    </FormControl>
		    <LoadingButton loading={loading} onClick={() => submitForm()} style={{backgroundColor: "#bc1200"}} variant="contained">
		    	Salvar dados
		    </LoadingButton>
		</form>
	);
}