import {useState, useEffect} from 'react';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

// baseUrl
import {baseUrl} from "../../../services/adalConfig.js";

{
  
}

export default function Oracoes(props) {

	const [valuesDict, setValuesDict] = useState({"id_tipo_oracao": null, "texto_oracao_pt": "<strong>Ave Maria, cheia de graça, o Senhor é convosco</strong>,\n\nbendita sois vós entre as mulheres e bendito é o fruto do vosso ventre, Jesus. ...", "texto_oracao_lt": "<strong>Ave, María, grátia plena, Dóminus tecum</strong>,\n\nbenedícta tu in muliéribus, et benedictus fructus ventris tui Jesus. ...", "nome_oracao": ""});
	const [rows, setRows] = useState([]);
	const [tiposOracoes, setTiposOracoes] = useState([]);
	const [check, setCheck] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const changeValues = (field, newValue) => {
		console.log("oi");
		var tempDict = valuesDict;

		tempDict = {...tempDict, [field]: newValue};
		console.log(tempDict);
		setValuesDict(tempDict);
	}

	async function handleEditRow(rowDict) {

		if (rowDict.texto_oracao_lt == "" || rowDict.texto_oracao_lt == null) {
			setCheck(false)
		} else {
			setCheck(true);
		}

		setValuesDict({...rowDict, id_tipo_oracao: {id_tipo_oracao: rowDict.id_tipo_oracao, nome_tipo_oracao: rowDict.nome_tipo_oracao}});
		props.setIsUpdateMode(true);
	}

	function cancelEditRow() {
		setValuesDict({"id_tipo_oracao": null, "texto_oracao_pt": "<strong>Ave Maria, cheia de graça, o Senhor é convosco</strong>,\n\nbendita sois vós entre as mulheres e bendito é o fruto do vosso ventre, Jesus. ...", "texto_oracao_lt": "<strong>Ave, María, grátia plena, Dóminus tecum</strong>,\n\nbenedícta tu in muliéribus, et benedictus fructus ventris tui Jesus. ...", "nome_oracao": ""});
		props.setIsUpdateMode(false);
		setCheck(false);
		atualizaDados();
	}

	async function submitForm() {

		if (props.isUpdateMode) {
			var postUrl = baseUrl + "/update-oracao";
		} else {
			var postUrl = baseUrl + "/cadastrar-oracao";
		}
		
		var payload = valuesDict;

		var emptyValues = [];
		var payloadKeys = Object.keys(payload);
		console.log("keys");
		console.log(payloadKeys);
		for (let i = 0; i < payloadKeys.length; i++) {
			if (payload[payloadKeys[i]] == "" || payload[payloadKeys[i]] == null) {
				if (check == false && payloadKeys[i] == "texto_oracao_lt") 
					continue;
				else 
					emptyValues.push(payloadKeys[i]);
			}
		}

		if (emptyValues.length > 0) {
			props.setAlertDict({open: true, texto: `Os seguintes campos estão vazios: ${emptyValues.join(", ")}`, severidade: "error"});
			return;
		}

		if (check == false) {
			payload["texto_oracao_lt"] = "";
		}

		payload["url"] = payload["nome_oracao"].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll("/", "").replaceAll("  ", " ").replaceAll("\n", "").replaceAll("\r", "-").replaceAll(" , ", "-").replaceAll(" ,", "-").replaceAll(", ", "-").replaceAll(" ", "-").replaceAll(",", "-").replaceAll(";", "-");

		payload["token"] = props.token;

		setLoading(true);

		axios.post(postUrl, {
				...payload,
				id_tipo_oracao: payload.id_tipo_oracao.id_tipo_oracao
			})
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				setLoading(false);
				props.setAlertDict({open: true, texto: "Sucesso ao inserir nova oração!", severidade: "success"});
				cancelEditRow();
            })
            .catch((exp) => {
            	setLoading(false);
				props.setAlertDict({open: true, texto: "Erro ao inserir nova oração.", severidade: "error"});
            });
	}

	const columns = [
		{
    		field: 'nome_oracao',
		    headerName: 'Nome Oração',
		    width: 140,
		    flex: 1
    	},
    	{
    		field: 'nome_tipo_oracao',
		    headerName: 'Tipo Oração',
		    width: 140,
		    flex: 1
    	},
    	{
    		field: 'url',
		    headerName: 'URL',
		    width: 140,
		    flex: 1,
		    renderCell: (params) => <a href={"/oracoes/" + params.row.url} target="_blank">{params.row.url}</a>
    	},
    	{
    		field: 'texto_oracao_lt',
		    headerName: 'Tem versão em Latim?',
		    width: 140,
		    flex: 1,
		    valueGetter: (value) => (value != "" && value != null) ? "Sim" : "Não"
    	},
		{
	        field: 'actions',
	        type: 'actions',
	        width: 80,
	        getActions: (params) => [
		        	          	<EditIcon
		        	          		onClick={() => handleEditRow(params.row)}
		        	          	/>,
		        	          	<DeleteIcon
					          		onClick={() => props.handleDeleteDialog("tb_oracoes", params.row.nome_oracao, params.row.id, atualizaDados)}
					          	/>
		        	        ]
	    }
    ];

    function atualizaDados() {
    	var getUrl = baseUrl + "/get-oracoes";

        axios.get(getUrl)
            .then(resp => { 
                setRows(resp.data); 
            });
    }

    function getTiposOracoes() {
    	var getUrl = baseUrl + "/get-tipos-oracoes";

        axios.get(getUrl)
            .then(resp => { 
            	setTiposOracoes(resp.data);
            });
    
    }

    useEffect(() => {
    	getTiposOracoes();
    }, []);

    useEffect(() => {

    	console.log("New value");
    	console.log(props.isEditMode);

    	if (props.isEditMode == true || rows.length == 0) {
    		atualizaDados();
    	}

    }, [props.isEditMode]);

    useEffect(() => {
    	if (props.isUpdateMode == false) {
    		cancelEditRow();
    	}
    }, [props.isUpdateMode]);

    //{"id_tipo_oracao": null, "texto_oracao_pt": "", "texto_oracao_lt": "", "nome_oracao": ""}

    if (props.isEditMode == true && props.isUpdateMode == false) {
    	return (
    		<DataGrid
    			autoHeight 
		        rows={rows}
		        columns={columns}
		        pageSizeOptions={[50]}
		        style={{width: "85%"}}
		    />
    	);
    } else
		return (
		<form noValidate onSubmit={() => console.log("oi turma")} id="formulario-login-cadastro-dados">
			<TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Nome Oração *"
	          	value={valuesDict["nome_oracao"]}
				onChange={(event) => {
				    changeValues("nome_oracao", event.target.value);
				}}
	        />
	       	<Autocomplete
				id="fixed-tags-demo"
				value={valuesDict["id_tipo_oracao"]}
				onChange={(event, newValue) => {
					changeValues("id_tipo_oracao", newValue)
				}}
				options={tiposOracoes}
				getOptionLabel={(option) => option.nome_tipo_oracao}
				style={{ width: "100%" }}
				renderInput={(params) => (
					<TextField {...params} 
						label="Tipo de Oração *" />
				)}
			/>
			<div style={{width: "100%"}}>
				<p>Dica: para deixar um texto em negrito, deixe-o desta forma: </p>
				<p style={{marginBottom: "15px"}}>{"<strong>{este texto ficará em negrito!}</strong>"}</p>
				<TextField
					multiline
					style={{width: "100%"}}
					inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
	  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
		          	label="Texto da Oração em Português *"
		          	value={valuesDict["texto_oracao_pt"]}
					onChange={(event) => {
					    changeValues("texto_oracao_pt", event.target.value);
					}}
		        />
		       	<div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "70px"}}>
		        	<KeyboardDoubleArrowDownIcon/>
		        	<p>Como ficará no site</p>
		        	<KeyboardDoubleArrowDownIcon/>
		        </div>
		        <div style={{width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "0px"}}>
                    <p style={{whiteSpace: "pre-line"}}>
                    	{
			        		valuesDict["texto_oracao_pt"].split(/(<strong>.*?<\/strong>)/).map(segment => {
		                    if (segment.includes('strong')) {
		                    const text = segment.replace('<strong>','').replace('</strong>', '');
		                    return <strong>{text}</strong>;
		                    } else {
		                    return segment;
		                    }
		                    })
	                    }
                    </p>
                </div>
		    </div>
		    <div style={{width: "100%"}}>
		    	<FormGroup>
		      		<FormControlLabel control={<Checkbox checked={check} onChange={(event) => setCheck(event.target.checked)}/>} label="Incluir versão em Latim?" />
			    </FormGroup>
			    {
			    	(check)
			    	&&
			    	<>
						<TextField
							multiline
							style={{width: "100%"}}
							inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
			  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
				          	label=""
				          	value={valuesDict["texto_oracao_lt"]}
							onChange={(event) => {
							    changeValues("texto_oracao_lt", event.target.value);
							}}
				        />
				       	<div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "70px"}}>
				        	<KeyboardDoubleArrowDownIcon/>
				        	<p>Como ficará no site</p>
				        	<KeyboardDoubleArrowDownIcon/>
				        </div>
				        <div style={{width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "0px"}}>
		                    <p style={{whiteSpace: "pre-line"}}>
		                    	{
					        		valuesDict["texto_oracao_lt"].split(/(<strong>.*?<\/strong>)/).map(segment => {
				                    if (segment.includes('strong')) {
				                    const text = segment.replace('<strong>','').replace('</strong>', '');
				                    return <strong>{text}</strong>;
				                    } else {
				                    return segment;
				                    }
				                    })
			                    }
		                    </p>
		                </div>
		            </>
			    }
		    </div>
		    <LoadingButton loading={loading} onClick={() => submitForm()} style={{backgroundColor: "#bc1200"}} variant="contained">
		    	Salvar dados
		    </LoadingButton>
		</form>
	);
}