import {useState, useEffect} from 'react';
import Divider from '@mui/material-next/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';

import Musicas from "./PaginasCadastro/Musicas";
import Missas from "./PaginasCadastro/Missas";
import CalendarioMissas from "./PaginasCadastro/CalendarioMissas";
import Oracoes from "./PaginasCadastro/Oracoes";
import TiposOracoes from "./PaginasCadastro/TiposOracoes";
import Subsidios from "./PaginasCadastro/Subsidios";
import CadastroArtigos from "./PaginasCadastro/CadastroArtigos";

import "./CadastroDados.css";

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

const dictTabelas = {
	"tb_artigos": "o artigo",
	"tb_calendario": "a data",
	"tb_missas": "a missa",
	"tb_musicas": "a música",
	"tb_oracoes": "a oração",
	"tb_subsidios": "o subsídio"
};

export default function Home(props) {

	const [open, setOpen] = useState(false);
	const [titulo, setTitulo] = useState();
	const [alertDict, setAlertDict] = useState({open: false, texto: "", severidade: ""});
	const [isUpdateMode, setIsUpdateMode] = useState(false);
	const [isEditMode, setIsEditMode] = useState(true);
	const [deleteDialog, setDeleteDialog] = useState({"id": -1, "nome": "", "tabela": "", "open": false, "loading": false, "refresh_function": null});

	const handleClose = () => {
		setAlertDict({open: false, texto: "", severidade: ""});
	}

	function handleDeleteDialog(nome_tabela, nome_registro, id, refreshFunction) {
		setDeleteDialog({"id": id, "nome": nome_registro, "tabela": nome_tabela, "open": true, "loading": false, "refresh_function": refreshFunction});
	}

	function handleDeleteConfirmation(refreshFunction) {
		setDeleteDialog({...deleteDialog, "loading": true});

		const payload = {table_name: deleteDialog.tabela, id_to_delete: deleteDialog.id};
		var postUrl = baseUrl + "/delete-registry";

		axios.post(postUrl, payload)
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				refreshFunction();
				setDeleteDialog({"id": -1, "nome": "", "tabela": "", "open": false, "loading": false, "refresh_function": null});
				setAlertDict({open: true, texto: "Sucesso ao deletar registro!", severidade: "success"});
            })
            .catch((exp) => {
            	setDeleteDialog({"id": -1, "nome": "", "tabela": "", "open": false, "loading": false, "refresh_function": null});
            	refreshFunction();
				setAlertDict({open: true, texto: "Erro ao deletar registro.", severidade: "error"});
            });

	}

	const cadastrosDict = {
		"Músicas": <Musicas handleDeleteDialog={handleDeleteDialog} token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>, 
		"Missas": <Missas handleDeleteDialog={handleDeleteDialog} token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,  
		"Calendário de Missas": <CalendarioMissas handleDeleteDialog={handleDeleteDialog} token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,
		"Tipos de Orações": <TiposOracoes handleDeleteDialog={handleDeleteDialog} token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,
		"Orações": <Oracoes handleDeleteDialog={handleDeleteDialog} token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,
		"Subsídios": <Subsidios handleDeleteDialog={handleDeleteDialog} token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,
		"Cadastro de Artigos": <CadastroArtigos handleDeleteDialog={handleDeleteDialog} token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>
	};

	const changePage = (newTitle) => {
		setTitulo(newTitle);
	}

	const cards = Object.keys(cadastrosDict).map((item, index) => {
		return (
			<div className="cadastro-dados-card-container">
				<div className="cadastro-dados-card" onClick={() => changePage(item)}>
					<p>{item}</p>
				</div>
			</div>
		)
	})

	const handleComeBack = () => {
		setTitulo(null);
		setIsEditMode(true);
		setIsUpdateMode(false);
	}

	useEffect(() => {
		console.log("Palhacada");
	}, [isEditMode]);

	return (
		<>
			<Dialog onClose={() => setDeleteDialog({"id": -1, "nome": "", "tabela": "", "open": false, "loading": false, "refresh_function": null})} open={deleteDialog.open}>
		    	<DialogTitle>Você realmente deseja deletar {dictTabelas[deleteDialog.tabela]} {deleteDialog.nome}?</DialogTitle>
		    	<div style={{padding: "50px", display: "flex", justifyContent: "center", gap: "45px"}}>
		    		<Button variant="contained" style={{backgroundColor: "#626262"}} onClick={() => setDeleteDialog({"id": -1, "nome": "", "tabela": "", "open": false, "loading": false, "refresh_function": null})}>Cancelar</Button>
				    <LoadingButton loading={deleteDialog.loading} variant="contained" style={{backgroundColor: "red"}} onClick={() => handleDeleteConfirmation(deleteDialog.refresh_function)}>
				    	Deletar
				    </LoadingButton>    		
		    	</div>
		    </Dialog>
			<Snackbar open={alertDict["open"]} autoHideDuration={6000} onClose={handleClose}>
		        <Alert
		          onClose={handleClose}
		          severity={alertDict["severidade"]}
		          variant="filled"
		          sx={{ width: '100%' }}
		        >
		         	{alertDict["texto"]}
		        </Alert>
		     </Snackbar>
			<div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}} id="div-master-cadastros">
				<div class="musica-home-header" style={{marginBottom: "40px"}}>
	                <Divider sx={{
	                    "&::before, &::after": {
	                    borderColor: "#bc1200",
	                    },
	                }}>
	                	<div style={{display: "flex", gap: "35px"}}>
		                	<Button style={titulo == null? {visibility: "hidden"} : {}} onClick={() => handleComeBack()}><ArrowBackIcon/><p style={{marginLeft: "10px"}}>Voltar</p></Button>
		                	{titulo? titulo : "Cadastro Dados"}
		                	{
		                		(isEditMode == false)?
		                		<Button style={titulo == null? {visibility: "hidden"} : {}} onClick={() => setIsEditMode(!isEditMode)}><SettingsIcon/><p style={{marginLeft: "10px"}}>Editar</p></Button>
		                		:
		                		((isUpdateMode == false)?
		                		<Button style={titulo == null? {visibility: "hidden"} : {}} onClick={() => setIsEditMode(!isEditMode)}><AddIcon/><p style={{marginLeft: "10px"}}>Adicionar</p></Button>
		                		:
		                		<Button style={{visibility: "hidden"}} onClick={() => setIsEditMode(!isEditMode)}><AddIcon/><p style={{marginLeft: "10px"}}>Adicionar</p></Button>)
		                	}
		                	
		                </div>
	                </Divider>
	            </div>
	            {
	            	(titulo != null)
	            	?
	            	cadastrosDict[titulo]
	            	:
	            	<div id="div-cards-home-cadastro-dados">
		            	{cards}
		            </div>
	            } 
			</div>
		</>
		);
}