import {useState, useEffect} from 'react';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// baseUrl
import {baseUrl} from "../../../services/adalConfig.js";

export default function CadastroArtigos(props) {

	const [valuesDict, setValuesDict] = useState({
	    "url": "",
	    "nome": "",
	    "resumo": "",
	    "autor": "",
	    "tema": "",
	    "dataCriacao": dayjs(new Date().toISOString().substring(0,10))
	});
	const [rows, setRows] = useState([]);
	const [temas, setTemas] = useState([]);
	const [loading, setLoading] = useState(false);
	const [mudarArtigo, setMudarArtigo] = useState(false);
	const [mudarImagem, setMudarImagem] = useState(false);
	const navigate = useNavigate();

	const changeValues = (field, newValue) => {
		console.log("oi");
		var tempDict = valuesDict;

		tempDict = {...tempDict, [field]: newValue};
		console.log(tempDict);
		setValuesDict(tempDict);
	}

	async function handleEditRow(rowDict) {

		var tempDict = {...rowDict, dataCriacao: dayjs(rowDict.dataCriacao.substring(0,10)), id: rowDict.id};

		delete tempDict["views"];

		setValuesDict(tempDict);
		props.setIsUpdateMode(true);
	}

	function cancelEditRow() {
		setValuesDict({
		    "nome": "",
		    "resumo": "",
		    "autor": "",
		    "tema": "",
		    "dataCriacao": dayjs(new Date().toISOString().substring(0,10))
		});
		props.setIsUpdateMode(false);
		atualizaDados();
	}

	function getBase64(file) {
	  return new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => {
	      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
	      if ((encoded.length % 4) > 0) {
	        encoded += '='.repeat(4 - (encoded.length % 4));
	      }
	      resolve(encoded);
	    };
	    reader.onerror = error => reject(error);
	  });
	}

	async function submitForm() {

		if (props.isUpdateMode) {
			var postUrl = baseUrl + "/update-artigo";
			var mensagemSucesso = "Sucesso ao editar artigo!";
			var mensagemErro = "Erro ao editar artigo.";
		} else {
			var postUrl = baseUrl + "/cadastrar-artigo";
			var mensagemSucesso = "Sucesso ao criar artigo!";
			var mensagemErro = "Erro ao editar artigo.";
		}
		
		var payload = valuesDict;

		var emptyValues = [];
		var payloadKeys = Object.keys(payload);
		console.log("keys");
		console.log(payloadKeys);
		for (let i = 0; i < payloadKeys.length; i++) {
			if (payload[payloadKeys[i]] == "" || payload[payloadKeys[i]] == null) {
				if (payloadKeys[i] == "tipo") 
					continue;
				else 
					emptyValues.push(payloadKeys[i]);
			}
		}

		if (emptyValues.length > 0) {
			props.setAlertDict({open: true, texto: `Os seguintes campos estão vazios: ${emptyValues.join(", ")}`, severidade: "error"});
			return;
		}

		payload["token"] = props.token;

		payload = { 
			...payload,
			dataCriacao: payload["dataCriacao"].toDate().toISOString().substring(0,10),
			mudarArtigo: mudarArtigo,
			mudarImagem: mudarImagem
		};

		console.log("we got");
		console.log(payload);

		if (mudarArtigo) {
			payload["artigo"] = {
				nome: payload["artigo"].name,
				base64: await getBase64(payload["artigo"]),
				tipo: payload["artigo"].type,
				size: payload["artigo"].size
			}
		}

		if (mudarImagem) {
			payload["imagem"] = {
				nome: payload["imagem"].name,
				base64: await getBase64(payload["imagem"]),
				tipo: payload["imagem"].type,
				size: payload["imagem"].size
			}
		}

		payload["url"] = payload["nome"].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll("/", "").replaceAll("  ", " ").replaceAll("\n", "").replaceAll("\r", "-").replaceAll(" , ", "-").replaceAll(" ,", "-").replaceAll(", ", "-").replaceAll(" ", "-").replaceAll(",", "-").replaceAll(";", "-");

		console.log("strange words");
		console.log(payload); // Passar como base64 para o backend, e converter para file lá!

		setLoading(true);

		await axios.post(postUrl, {
			...payload,
		})
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				setLoading(false);
				props.setAlertDict({open: true, texto: mensagemSucesso, severidade: "success"});
            })
            .catch((exp) => {
            	setLoading(false);
				props.setAlertDict({open: true, texto: mensagemErro, severidade: "error"});
            });

        if (props.isUpdateMode) cancelEditRow();
	}

	const columns = [
    	{
			field: 'nome',
		    headerName: 'titulo',
		    width: 140,
		    flex: 1
    	},
    	{
    		field: 'url',
		    headerName: 'URL',
		    width: 140,
		    flex: 1,
		    renderCell: (params) => {
		    	return (<a href={'/artigos/' + params.row.url}>{params.row.url}</a>)
		    }
    	}, 
    	{
			field: 'autor',
		    headerName: 'Autor',
		    width: 140,
		    flex: 1
    	},
    	{
			field: 'tema',
		    headerName: 'Tema',
		    width: 140,
		    flex: 1
    	},
    	{
			field: 'views',
		    headerName: 'Views',
		    width: 140,
		    flex: 1
    	},
    	{
			field: 'dataCriacao',
		    headerName: 'Data de Criação',
		    width: 140,
		    flex: 1,
		    renderCell: (params) => params.row.dataCriacao.substring(8,10) + "/" + params.row.dataCriacao.substring(5,7) + "/" + params.row.dataCriacao.substring(0,4)
    	},
		{
	        field: 'actions',
	        type: 'actions',
	        width: 80,
	        getActions: (params) => {
	    		return ([
	        	          	<EditIcon
	        	          		onClick={() => handleEditRow(params.row)}
	        	          	/>,
	        	          	<DeleteIcon
				          		onClick={() => props.handleDeleteDialog("tb_artigos", params.row.nome, params.row.id, atualizaDados)}
				          	/>
	        	        ]);
	       	}
	    }
    ];

    function atualizaDados() {
    	var getUrl = baseUrl + "/get-all-artigos";

        axios.get(getUrl)
            .then(resp => { 
                setRows(resp.data); 
            });
    }

    useEffect(() => {

    	console.log("New value");
    	console.log(props.isEditMode);

    	if (props.isEditMode == true || rows.length == 0) {
    		atualizaDados();
    	}

    }, [props.isEditMode]);

    useEffect(() => {
    	if (props.isUpdateMode == false) {
    		cancelEditRow();
    	}
    }, [props.isUpdateMode]);

    useEffect(() => {
    	if (props.isUpdateMode == false) {
    		setMudarArtigo(true);
    		setMudarImagem(true);
    	} else {
    		setMudarArtigo(false);
    		setMudarImagem(false);
    	}
    }, [props.isUpdateMode, props.isEditMode]);

    useEffect(() => {
    	var getUrl = baseUrl + "/get-possible-temas";

        axios.get(getUrl)
            .then(resp => { 
                setTemas(resp.data); 
            });
    	
    }, []);

    if (props.isEditMode == true && props.isUpdateMode == false) {
    	return (
    		<DataGrid
    			autoHeight 
		        rows={rows}
		        columns={columns}
		        pageSizeOptions={[50]}
		        style={{width: "85%"}}
		    />
    	);
    } else
		return (
		<form noValidate onSubmit={() => console.log("oi turma")} id="formulario-login-cadastro-dados">
			<TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Título *"
	          	value={valuesDict["nome"]}
				onChange={(event) => {
				    changeValues("nome", event.target.value);
				}}
	        />
	        <Autocomplete
				id="fixed-tags-demo"
				freeSolo
				inputValue={valuesDict["tema"]}
				onInputChange={(event, newValue) => {
					changeValues("tema", newValue)
				}}
				options={temas}
				style={{ width: "100%" }}
				renderInput={(params) => (
					<TextField {...params} 
						label="Tema *" />
				)}
			/>
	        <TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Resumo *"
	          	value={valuesDict["resumo"]}
				onChange={(event) => {
				    changeValues("resumo", event.target.value);
				}}
	        />
	        <TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Autor *"
	          	value={valuesDict["autor"]}
				onChange={(event) => {
				    changeValues("autor", event.target.value);
				}}
	        />
	        <div style={{width: "100%"}}>
	        	{
			   		(props.isUpdateMode == false)?
			   		<p style={{"color": "black", fontSize: "16px"}}>Artigo *</p>
			   		:
			   		<FormGroup>
			      		<FormControlLabel disabled={props.isUpdateMode == false} control={<Checkbox checked={mudarArtigo} onChange={(event) => setMudarArtigo(event.target.checked)}/>} label={<p style={{"color": "black", fontSize: "16px"}}>Mudar artigo?</p>}/>
				    </FormGroup>
			   	}
			    {
			    	(mudarArtigo)
			    	&&
			    	<>
			    	<a href="https://docs.google.com/document/d/1YlwM-BEs4DhzQ8-gbhmdap_wybYllljHJASpEopRp2A/edit?usp=sharing" target="_blank" className="link-cadastro-dados" style={{marginBottom: "5px"}}>Acesse o manual para criação do artigo</a>
			    	<input
				        type="file"
				        style={{marginTop: "10px"}}
				        onChange={(event) => {
						    changeValues("artigo", event.target.files[0]);
						}}
				    />
				    </>
			    }
		    </div>
		   	<div style={{width: "100%"}}>
			   	{
			   		(props.isUpdateMode == false)?
			   		<p style={{"color": "black", fontSize: "16px"}}>Imagem *</p>
			   		:
			   		<FormGroup>
			      		<FormControlLabel control={<Checkbox checked={mudarImagem} onChange={(event) => setMudarImagem(event.target.checked)}/>} label={<p style={{"color": "black", fontSize: "16px"}}>Mudar imagem?</p>}/>
				    </FormGroup>
			   	}
			    {
			    	(mudarImagem)
			    	&&
			    	<>
			    	<input
				        type="file"
				        style={{marginTop: "10px"}}
				        onChange={(event) => {
						    changeValues("imagem", event.target.files[0]);
						}}
				    />
				    </>
			    }
		    </div>      	
	        <div>
		        <p style={{fontSize: "16px"}}>Data de Criação *</p>
		        <LocalizationProvider dateAdapter={AdapterDayjs}>
		       		<DateCalendar value={valuesDict["dataCriacao"]} onChange={(newValue) => changeValues("dataCriacao", newValue)} />
		       	</LocalizationProvider>
	       	</div>
		    <LoadingButton loading={loading} onClick={() => submitForm()} style={{backgroundColor: "#bc1200"}} variant="contained">
		    	Salvar dados
		    </LoadingButton>
		</form>
	);
}