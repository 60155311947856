// NavBar.js
import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

import "./NavBar.css";
import logo from "../images/logo-image-21.png";

function getWindowDimensions() {
   const { innerWidth: width, innerHeight: height } = window;
   return {
     width,
     height
   };
 }

export default function NavBar(props) {

   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

   useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   if (window.innerWidth <= 768) {
      return (<NavBarMobile/>);
   } else {
      return (<NavBarDesktop/>);
   }
};

function NavBarDesktop(props) {

   return (
      <div style={{position: "fixed", top: "0", width: "100%", "z-index": "1000"}}>
         <nav class="navigation-bar">
            <div class="navigation-list">
               <NavLink style={{width: "50px", heigth: "50px", cursor: "pointer"}} to="/">
                  <img style={{width: "50px", heigth: "50px", cursor: "pointer"}} src={logo}/>
               </NavLink>
               <p class="navigation-item">
                  <NavLink style={{color: "white", textDecoration: "none"}} to="/musica-liturgica">Músicas Litúrgicas</NavLink>
               </p>
               <p class="navigation-item">
                  <NavLink style={{color: "white", textDecoration: "none"}} class="navigation-items" to="/oracoes">Orações</NavLink>
               </p>
                <p class="navigation-item">
                  <NavLink style={{color: "white", textDecoration: "none"}} class="navigation-items" to="/artigos">Artigos</NavLink>
               </p>
               <p class="navigation-item">
                  <NavLink style={{color: "white", textDecoration: "none"}} class="navigation-items" to="/roteiros">Roteiros</NavLink>
               </p>
               <p class="navigation-item">
                  <NavLink style={{color: "white", textDecoration: "none"}} class="navigation-items" to="/rituais">Rituais</NavLink>
               </p>
               <p class="navigation-item">
                  <NavLink style={{color: "white", textDecoration: "none"}} to="documentos/">Documentos</NavLink>
               </p>
               <p class="navigation-item">
                  <a style={{color: "white", textDecoration: "none"}} target="_blank" href="https://open.spotify.com/show/3QEuFdv0jhZzYwVmwNsSp8">Homilia Diária</a>
               </p>
               <p class="navigation-item">
                  <a style={{color: "white", textDecoration: "none"}} target="_blank" href="https://www.cnbb.org.br/liturgia-diaria/">Liturgia Diária</a>
               </p>
            </div>
         </nav>
      </div>
   );

}

function NavBarMobile(props) {

   const [open, setOpen] = useState(false);
   const navigate = useNavigate();

   const toggleDrawer = (anchor, shouldOpen) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setOpen(shouldOpen);
    };

    function handleClickLogo() {
      navigate("/");
      setOpen(false);
    }

    return (
      <div style={{position: "fixed", top: "0", width: "100%", "z-index": "1000"}}>
         <nav class="navigation-bar">
            <div class="navigation-list" style={{justifyContent: "space-between"}}>
               <div onClick={() => setOpen(true)} style={{display: "flex", alignItems: "center", gap: "10px", marginLeft: "30px", color: "white"}}>
                  <MenuIcon style={{color: "white", width: "20px", height: "20px"}}/>
                  <p style={{color: "white", fontSize: "18px"}}>Menu</p>
               </div>
                  <div onClick={() => handleClickLogo()} style={{width: "50px", heigth: "50px", cursor: "pointer"}}>
                     <img style={{width: "50px", heigth: "50px", cursor: "pointer"}} src={logo}/>
                  </div>
               <div style={{visibility: "hidden", marginRight: "30px", display: "flex", alignItems: "center", gap: "10px"}}>
                  <MenuIcon style={{color: "white", width: "20px", height: "20px"}}/>
                  <p style={{color: "white", fontSize: "18px"}}>Menu</p>
               </div>
            </div>
         </nav>
         <Drawer
            anchor={"left"}
            open={open}
            onClose={toggleDrawer("left", false)}
            sx={{
               '& .MuiDrawer-paper': { width: "60%", overflow: "hidden" },
             }}
          >
            <div class="navigation-left-navbar">
               <div onClick={() => handleClickLogo()} style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
                  <img style={{width: "50px", heigth: "50px", cursor: "pointer"}} src={logo}/>
               </div>
                  {/* <p style={{fontSize: "18px", textAlign: "center", color: "white"}}><i>LAUDATE DOMINUM OMNES GENTES</i></p> */}
                  <div class="navigation-left-navbar-items-div">
                     <NavLink onClick={() => setOpen(false)} to="/musica-liturgica" class="navigation-left-navbar-items" style={{color: "white", textDecoration: "none", width: "100%"}}>
                        <p style={{color: "white"}} class="navigation-left-navbar-items">Músicas Litúrgicas</p>
                     </NavLink>
                     <NavLink onClick={() => setOpen(false)} to="/oracoes" class="navigation-left-navbar-items" style={{color: "white", textDecoration: "none", width: "100%"}}>
                        <p style={{color: "white"}} class="navigation-left-navbar-items">Orações</p>
                     </NavLink>
                     <a target="_blank" href="https://open.spotify.com/show/3QEuFdv0jhZzYwVmwNsSp8" class="navigation-left-navbar-items" style={{color: "white", textDecoration: "none", width: "100%"}}>
                        <p style={{padding: "0", color: "white", fontWeight: "normal", fontFamily: 'aktiv-grotesk', overflow: "hidden", borderWidth: "0px 0px 0px 0px", boxSizing: "border-box", fontSize: "calc(1.2rem + 0.4vh)", display: "flex", alignItems: "center"}}>Homilia Diária</p>
                     </a>
                     <a target="_blank" href="https://www.cnbb.org.br/liturgia-diaria/" class="navigation-left-navbar-items" style={{color: "white", textDecoration: "none", width: "100%"}}>
                        <p style={{padding: "0", color: "white", fontWeight: "normal", fontFamily: 'aktiv-grotesk', overflow: "hidden", borderWidth: "0px 0px 0px 0px", boxSizing: "border-box", fontSize: "calc(1.2rem + 0.4vh)", display: "flex", alignItems: "center"}}>Liturgia Diária</p>
                     </a>
                     <NavLink onClick={() => setOpen(false)} to="/rituais" class="navigation-left-navbar-items" style={{color: "white", textDecoration: "none", width: "100%"}}>
                        <p style={{color: "white"}} class="navigation-left-navbar-items">Rituais</p>
                     </NavLink>
                     <NavLink onClick={() => setOpen(false)} to="/artigos" class="navigation-left-navbar-items" style={{color: "white", textDecoration: "none", width: "100%"}}>
                        <p style={{color: "white"}} class="navigation-left-navbar-items">Artigos</p>
                     </NavLink>
                     <NavLink onClick={() => setOpen(false)} to="/documentos" class="navigation-left-navbar-items" style={{color: "white", textDecoration: "none", width: "100%"}}>
                        <p style={{color: "white"}} class="navigation-left-navbar-items">Documentos</p>
                     </NavLink>
                     <NavLink onClick={() => setOpen(false)} to="/roteiros" class="navigation-left-navbar-items" style={{color: "white", textDecoration: "none", width: "100%"}}>
                        <p class="navigation-left-navbar-items" style={{borderWidth: "2px 0px 2px 0px", color: "white"}} to="/">Roteiros</p>
                     </NavLink>
                     <div style={{padding: "20px"}} onClick={() => setOpen(false)}>
                        <ArrowBackIcon style={{color: "white", width: "calc(1.50rem + 2vh)", height: "calc(1.50rem + 2vh)"}}/>
                     </div>
                  </div>
               </div>
          </Drawer>
      </div>
   )

}